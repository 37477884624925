export default function dynamicMask(
  maskit: {
    (value: any, mask: any, masked: boolean | undefined, tokens: any): string
    (
      arg0: any,
      arg1: any,
      arg2: any,
      arg3: any,
    ): { (): any; new (): any; length: number }
  },
  masks: any[],
  tokens: any,
) {
  masks = masks.sort(
    (a: string | any[], b: string | any[]) => a.length - b.length,
  )
  return function (value: any, _mask: any, masked: any) {
    let i = 0
    while (i < masks.length) {
      const currentMask = masks[i]
      i++
      const nextMask = masks[i]
      if (
        !(
          nextMask &&
          maskit(value, nextMask, masked, tokens).length > currentMask.length
        )
      ) {
        return maskit(value, currentMask, masked, tokens)
      }
    }
    return '' // empty masks
  }
}
