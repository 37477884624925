<template>
  <div class="zn-input-field w-full" :data-test="dataTestComputed">
    <slot name="label">
      <label
        class="mb-2 block w-fit text-base font-semibold"
        :class="[
          labelClass,
          errors?.length || errorsData?.length ? 'text-red-light' : '',
        ]"
        :for="id"
        >{{ title }}
        <slot name="after-label" />
      </label>
    </slot>
    <div class="relative">
      <slot name="leading" />

      <input
        :id="id"
        :class="`focus:border-teal-primary border-grays-light placeholder:text-grays-medium disabled:bg-grays-lightest block w-full rounded-lg border px-3.5 py-2.5 text-lg focus:[box-shadow:none] disabled:cursor-not-allowed ${classes} ${
          errorsData && errorsData.length ? 'border-red-light' : ''
        }`"
        :type="type"
        :name="name"
        :disabled="disabled"
        :value="display"
        :maxlength="maxLength"
        :placeholder="placeholder"
        @input="onInputEvent"
        @change="(event) => onChangeEvent(event, 'change')"
        @blur="(event) => onChangeEvent(event, 'blur')"
        @focus="onFocusEvent"
      />
    </div>
    <div
      v-if="errorsData && errorsData.length"
      :class="['errors mt-2', `text-${errorMessageJustify}`]"
    >
      <ul>
        <li
          v-for="(item, index) in errorsData"
          :key="index"
          class="text-red-light text-sm"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, defineComponent } from 'vue'
import masker from '@/utils/mask/masker'
import defaultTokens from '@/utils/mask/tokens'
import { onMounted } from '#imports'

defineComponent({ name: 'ZnInputField' })

const props = defineProps({
  title: {
    type: String,
    default: undefined,
    required: false,
  },
  name: {
    type: String,
    default: undefined,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
    required: false,
  },
  validateOnMount: {
    type: Boolean,
    default: false,
    required: false,
  },
  type: {
    type: String,
    default: 'text',
    required: false,
  },
  maxLength: {
    type: Number,
    default: null,
    required: false,
  },
  id: {
    type: String,
    default: undefined,
    required: false,
  },
  value: {
    type: [String, Number],
    required: false,
    default: '',
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  rules: {
    type: [Array, Function],
    required: false,
    default: undefined,
  },
  required: {
    type: Boolean,
    required: false,
    default: false,
  },
  requiredMessage: {
    type: String,
    required: false,
    default: undefined,
  },
  mask: {
    type: [String, Array],
    required: false,
    default: undefined,
  },
  valueMasked: {
    type: Boolean,
    required: false,
    default: false,
  },
  tokens: {
    type: Object,
    required: false,
    default: () => defaultTokens,
  },
  classes: {
    type: String,
    required: false,
    default: undefined,
  },
  errors: {
    type: Array,
    required: false,
    default: undefined,
  },
  errorMessageJustify: {
    validator: function (value: string) {
      return ['start', 'end', 'center'].includes(value)
    },
    require: false,
    default: 'center',
  },
  dataTest: {
    type: String,
    required: false,
    default: '',
  },
  labelClass: {
    type: String,
    require: false,
    default: '',
  },
})

const emits = defineEmits(['change', 'input', 'onValidate', 'focus'])

const errorsData = ref<any>(props.errors)

const maskData = ref(props.mask)

const display = ref<any>(props.value)

const dataTestComputed = computed(() => {
  return props.dataTest || `input-field-${props.name || props.id}`
})

watch(
  () => props.mask,
  (newValue: any) => {
    if (maskData.value && !newValue) {
      display.value = masker(display.value, maskData.value, false, props.tokens)
    } else if ((!maskData.value && newValue) || maskData.value !== newValue) {
      display.value = masker(display.value, newValue, true, props.tokens)
    }
    maskData.value = newValue
  },
)

watch(
  () => props.value,
  (newValue: any) => {
    if (maskData.value) {
      display.value = masker(newValue, maskData.value, true, props.tokens)
    } else {
      display.value = newValue
    }
  },
)

watch(
  () => props.errors,
  (newValue) => {
    errorsData.value = newValue
  },
)

const validateRules = (value: any) => {
  errorsData.value = []

  if (props.required && !value) {
    let errorToAdd = 'This field is required'
    if (props.requiredMessage) {
      errorToAdd = props.requiredMessage
    } else if (props.title) {
      errorToAdd = props.title + ' is required.'
    }
    errorsData.value.push(errorToAdd)
    emits('onValidate', errorsData.value)
    return
  }

  if (!props.rules) {
    return
  }

  if (Array.isArray(props.rules)) {
    props.rules.forEach((rule) => validateRule(rule, value))
  } else {
    validateRule(props.rules, value)
  }
  emits('onValidate', errorsData.value)
}

const validateRule = (rule: any, value: any) => {
  const ruleReturn = rule(value)
  if (ruleReturn !== true) {
    errorsData.value.push(ruleReturn)
  }
}

const onInputEvent = ($event: any) => {
  let value = $event.target.value
  if (maskData.value) {
    value = masker(value, maskData.value, props.valueMasked, props.tokens)
    display.value = masker(value, maskData.value, true, props.tokens)
    $event.target.value = display.value
  } else {
    display.value = value
  }
  emits('input', value)
}

const onChangeEvent = ($event: any, type: any) => {
  let newValue = $event.target.value

  if (maskData.value && !props.valueMasked) {
    newValue = masker(newValue, maskData.value, false, props.tokens)
  }

  validateRules(newValue)
  emits('change', newValue, type)
}

const onFocusEvent = (event: any) => {
  errorsData.value = []
  emits('focus', event)
}

onMounted(() => {
  if (props.value && props.validateOnMount) {
    validateRules(props.value)
  }
})
</script>
