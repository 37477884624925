import maskit from './maskit'
import dynamicMask from './dynamic-mask'
// Facade to maskit/dynamicMask when mask is String or Array
export default function (
  value: string | any[],
  mask: string | any[],
  masked = true,
  tokens: { [x: string]: any },
) {
  return Array.isArray(mask)
    ? dynamicMask(maskit as any, mask, tokens)(value, mask, masked)
    : maskit(value, mask, masked, tokens)
}
